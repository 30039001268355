import React from "react";
import { toastify } from "src/utils/toast";
import Iconify from "src/components/iconify/Iconify";
import { Button, CardMedia, Stack, Typography } from "@mui/material";

const Initials = ({ initials, setInitials }) => {
  async function onUploading(e) {
    const file = e.target.files[0];
    if (file) {
      const tempExtention = file.name.split(".");
      const fileExtention =
        tempExtention[tempExtention.length - 1].toLowerCase();

      const allowedFileExtentions = ["png"];
      if (!allowedFileExtentions.includes(fileExtention)) {
        toastify(
          "warning",
          "Please upload valid file type. File type allowed: PNG"
        );
        return;
      }

      if (file.size > 20000000) {
        toastify("warning", "File size should be less than 20MB");
        return;
      }

      const newImages = Array.from(e.target.files);
      if (initials.length + newImages.length > 11) {
        alert("You can only upload up to 10 images.");
        return;
      }

      setInitials((prevImages) => [...prevImages, ...newImages]);
    }
  }

  return (
    <div>
      <Typography variant="subtitle1" sx={{ my: 2 }}>
        Select Initials:
      </Typography>
      <input
        accept="image/*"
        id={`icon-button-file`}
        type="file"
        hidden
        multiple
        onChange={(e) => {
          onUploading(e);
        }}
        onClick={(e) => {
          e.target.value = null;
        }}
      />

      <label htmlFor={`icon-button-file`}>
        <Button
          variant="outlined"
          component="span"
          startIcon={<Iconify icon="material-symbols:image-outline"></Iconify>}
          fullWidth
          sx={{ mb: 2 }}
        >
          Upload Images
        </Button>
      </label>
      {initials?.length ? (
        <Stack direction={"row"} spacing={3} mt={2} flexWrap={"wrap"}>
          {initials?.map((image, index) => (
            <CardMedia
              key={index}
              component="img"
              image={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              alt="Catalog image"
              sx={{ width: "80px", height: "80px" }}
            />
          ))}
        </Stack>
      ) : (
        ""
      )}
    </div>
  );
};

export default Initials;
