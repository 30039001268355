import { getAllOrdersList } from ".";
import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { getWalletAmount } from "src/pages/settings/utils";
import { setModalState } from "src/redux/actions/modalActions";
import { loadingBtnAction } from "src/redux/actions/userActions";

export const approveOrder = async (
  orders,
  totalAmount,
  paymentMode,
  userType,
  setSelected,
  dispatch
) => {
  const payload =
    userType === "PrintProvider"
      ? {
          orders: orders,
        }
      : {
          user_type: userType === "Seller" ? "seller" : "admin",
          total_amount: totalAmount,
          payment_mode: paymentMode,
          orders: orders,
        };

  dispatch(loadingBtnAction(true));

  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";

  try {
    const res = await request.post(
      userType === "PrintProvider"
        ? "/print-provider/shipstation/approve"
        : `/shipstation`,
      payload
    );

    if (res) {
      getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
      dispatch(setModalState(undefined));
      setSelected([]);

      if (userType === "Seller") {
        getWalletAmount(dispatch);
      }

      toastify("success", res.data.message);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};
