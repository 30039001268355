import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { fShortenNumber } from "src/utils/formatNumber";
import { getFormattedMonthYear } from "src/utils/formatDate";

const TotalAdminRevenueChart = () => {
  const allUserStats = useSelector((state) => state.user.allUserStats);
  const data = allUserStats
    ? allUserStats?.monthly_admin_revenue?.map((item) => ({
        year: item.year,
        month: item.month,
        totalRevenue: parseFloat(item.total_revenue),
      }))
    : [];

  const renderTooltipContent = (value, name, props) => {
    return `$${fShortenNumber(value)}`;
  };

  return (
    <ResponsiveContainer width="100%" height={380}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={getFormattedMonthYear} />
        <YAxis />
        <Tooltip formatter={renderTooltipContent} />
        <Legend />
        <Bar dataKey="totalRevenue" fill="#82ca9d" name="Total Revenue" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TotalAdminRevenueChart;
