import request from "src/utils/request";
import ApproveOrder from "../../sections/modals/order/approveOrder";
import { toastify } from "src/utils/toast";
import { fToNow } from "src/utils/formatTime";
import { Paper, styled } from "@mui/material";
import { loadingAction } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";
import { getUploadedImage } from "src/sections/modals/order/utils";
import { productAttributes } from "src/sections/modals/order/variations/utils";

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

export const TABLE_HEAD = [
  { label: "Order ID #", id: "etsy_order_id" },
  { label: "Customer Name", id: "name" },
  { label: "Total Items", id: "total_items" },
  { label: "Amount", id: "amount" },
  { label: "Order Date", id: "date" },
  { label: "Approval Date", id: "approval_date" },
];

export const formatTableData = (row, userType) => {
  const {
    id,
    etsy_order_id,
    amazon_order_id,
    user_name,
    name,
    date,
    approval_date,
    total_items,
    amount,
    etsy_cost,
    tracking_id,
    is_amazon,
    is_etsy,
    amazon_cost,
  } = row;

  let calculatedAmount = row ? calculateAmount(row) : 0;

  console.log(approval_date, "approval_date");
  
  const TABLE_DATA = [
    {
      label: "Order ID #",
      id: "etsy_order_id",
      value: etsy_order_id ? (
        <a
          href={` https://www.etsy.com/your/orders/sold/new?order_id=${etsy_order_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {etsy_order_id}
        </a>
      ) : amazon_order_id ? (
        amazon_order_id
      ) : (
        id
      ),
    },
    { label: "Customer Name", id: "name", value: name },
    { label: "Seller Name", id: "user_name", value: user_name },
    {
      label: "Total Items",
      id: "total_items",
      value: total_items,
    },
    {
      label: "Amount",
      id: "amount",
      value: `$${parseFloat(calculatedAmount).toFixed(2)}`,
    },

    {
      label: `${
        is_etsy === 1 ? " Etsy" : is_amazon === 1 ? "Amazon" : "ICP"
      } Cost`,
      id: "etsy_cost",
      value: `$${
        is_etsy === 1 && etsy_cost
          ? parseFloat(etsy_cost).toFixed(2)
          : is_amazon === 1
          ? parseFloat(amazon_cost).toFixed(2)
          : amount
      }`,
    },
    { label: "Order Date", id: "date", value: fToNow(date) },
    {
      label: "Approval Date",
      id: "approval_date",
      value: approval_date ?? "-",
    },
    {
      label: "Tracking ID",
      id: "tracking_id",
      value: tracking_id ? tracking_id : "-",
    },
  ];

  let tableData =
    userType === "Seller"
      ? TABLE_DATA.filter((item) => item.id !== "user_name")
      : TABLE_DATA;

  return tableData;
};

const checkimageURL = (url) => {
  if (typeof url === "string") {
    const canvaUrl = url.search("canva");
    const kittlUrl = url.search("kittl");
    const adobeUrl = url.search("adobe");

    if (canvaUrl > 0 || kittlUrl > 0 || adobeUrl > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    const canvaUrl = url[0].search("canva");
    const kittlUrl = url[0].search("kittl");
    const adobeUrl = url[0].search("adobe");

    if (canvaUrl > 0 || kittlUrl > 0 || adobeUrl > 0) {
      return true;
    } else {
      return false;
    }
  }
};

export const calculateAmount = (order) => {
  let additionalUnits = order?.orderDetails?.length - 1;
  let additionalShippingCost = additionalUnits * 2.5;

  let discounted_amount = order ? bulkDiscount(order) : 0;

  let personalization_details = order.orderDetails?.map(
    (item) => item?.personalization_details
  );

  const variationTotal = personalization_details
    ?.map((array) => calculateTotal(array))
    .reduce((acc, val) => acc + val, 0);

  let amount = discounted_amount + variationTotal;

  let subTotal =
    order?.priorty_mail_shipping === 1
      ? parseFloat(amount) + 5.99 + additionalShippingCost
      : parseFloat(amount);

  return parseFloat(subTotal).toFixed(2);
};

const pricePerInitial = 1.89;
const pricePerBirthstone = 0.89;

export function calculateTotal(array) {
  let total = 0;

  array?.forEach((item) => {
    if (item.formatted_name === "Initials") {
      total += item.formatted_value.length * pricePerInitial;
    }
    if (item.formatted_name === "Birthstones") {
      total += item.formatted_value.length * pricePerBirthstone;
    }
  });

  return total;
}
export const bulkDiscount = (order) => {
  let frosted_cup_total_price = 0;
  let ornament_total_price = 0;
  let tumbler_total_price = 0;
  let jewelery_total_price = 0;
  let bracelet_total_price = 0;
  let keychain_total_price = 0;
  let birthstones_total_price = 0;
  let initials_total_price = 0;

  let gerber_cotton_total_price = 0;
  let organic_toddler_total_price = 0;
  let organic_youth_total_price = 0;

  let tumbler_lid_total_price = 0;
  let tumbler_straw_total_price = 0;

  let frosted_cup_lid_total_price = 0;
  let frosted_cup_straw_total_price = 0;
  let frosted_cup_lid_straw_combo_total_price = 0;

  let standard_shipping_tumbler_resend_total_price = 0;
  let priority_shipping_tumbler_resend_total_price = 0;
  let standard_shipping_jewelry_resend_total_price = 0;
  let priority_shipping_jewelry_resend_total_price = 0;
  let standard_shipping_ornament_resend_total_price = 0;
  let priority_shipping_ornament_resend_total_price = 0;

  let frostedCup = order?.orderDetails?.filter(
    (item) => item?.catalog?.id === 40
  );
  let tumblers = order?.orderDetails?.filter((item) => item?.catalog?.id === 5);
  let ornaments = order?.orderDetails?.filter((item) => item.catalog?.id === 6);
  let jewelery = order?.orderDetails?.filter((item) => item.catalog?.id === 27);
  let bracelets = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 38
  );
  let keychains = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 39
  );
  let birthstones = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 46
  );
  let initials = order?.orderDetails?.filter((item) => item.catalog?.id === 45);

  let gerberCotton = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 47
  );
  let organicToddler = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 48
  );
  let organicYouth = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 49
  );

  let tumbler_lid = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 30
  );
  let tumbler_straw = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 31
  );

  let frosted_cup_lid = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 43
  );
  let frosted_cup_straw = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 42
  );
  let frosted_cup_straw_lid_combo = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 44
  );

  let standard_shipping_tumbler_resend = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 28
  );
  let priority_shipping_tumbler_resend = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 29
  );

  let standard_shipping_jewelry_resend = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 34
  );
  let priority_shipping_jewelry_resend = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 35
  );

  let standard_shipping_ornament_resend = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 36
  );
  let priority_shipping_ornament_resend = order?.orderDetails?.filter(
    (item) => item.catalog?.id === 37
  );

  const frostedCup_price = 8.99;
  const tumbler_price = 9.67;
  const ornament_price = 6.09;
  const jewelery_price = 8.79;
  const bracelet_price = 8.79;
  const keychain_price = 7.79;
  const tumbler_lid_price = 1.99;
  const tumbler_straw_price = 1;
  const birthstones_price = 0.89;
  const initials_price = 1.89;

  const gerber_cotton_price = 8.9;
  const organic_toddler_price = 9.9;
  const organic_youth_price = 9.9;

  const birthstones_ship_price = 0;
  const initials_ship_price = 0;

  const frosted_cup_lid_price = 1.99;
  const frosted_cup_straw_price = 1;
  const frosted_cup_lid_straw_price = 2.5;

  const ornament_initial_ship_price = 4.2;
  const ornament_additional_ship_price = 1.99;

  const print_provider_initial_ship_price = 4.0;
  const print_provider_additional_ship_price = 0.99;

  const frosted_cup_initial_ship_price = 5.95;
  const frosted_cup_additional_ship_price = 3.75;

  const tumbler_ship_price =
    tumblers?.length < 3
      ? 5.83
      : tumblers?.length === 3
      ? 4.83
      : tumblers?.length > 3
      ? 3.83
      : 0;

  const jewelery_initial_ship_price = 4.2;
  const jewelery_additional_ship_price = 1.99;

  const bracelet_initial_ship_price = 4.2;
  const bracelet_additional_ship_price = 1.99;

  const keychain_initial_ship_price = 4.2;
  const keychain_additional_ship_price = 1.99;

  const frosted_cup_lid_ship_price = 3.99;
  const frosted_cup_straw_ship_price = 3.99;
  const frosted_cup_lid_straw_ship_price = 3.99;

  const standard_shipping_ship_price = 5.83;
  const priority_shipping_ship_price = 11.82;
  const tumbler_lid_ship_price = 3.99;
  const tumbler_straw_ship_price = 3.99;

  if (frostedCup?.length === 1) {
    frosted_cup_total_price = frostedCup_price + frosted_cup_initial_ship_price;
  } else if (frostedCup?.length > 1) {
    let frostedCup_shippingCost =
      frosted_cup_initial_ship_price +
      frosted_cup_additional_ship_price * (frostedCup?.length - 1);
    frosted_cup_total_price =
      frostedCup_price * frostedCup?.length + frostedCup_shippingCost;
  }

  for (let i = 0; i < tumblers?.length; i++) {
    tumbler_total_price =
      tumbler_total_price + tumbler_price + tumbler_ship_price;
  }

  if (ornaments?.length === 1) {
    ornament_total_price = ornament_price + ornament_initial_ship_price;
  } else if (ornaments?.length > 1) {
    let ornament_shippingCost =
      ornament_initial_ship_price +
      ornament_additional_ship_price * (ornaments?.length - 1);
    ornament_total_price =
      ornament_price * ornaments?.length + ornament_shippingCost;
  }

  if (gerberCotton?.length === 1) {
    gerber_cotton_total_price =
      gerber_cotton_price + print_provider_initial_ship_price;
  } else if (gerberCotton?.length > 1) {
    let gerber_cotton_shippingCost =
      print_provider_initial_ship_price +
      print_provider_additional_ship_price * (gerberCotton?.length - 1);
    gerber_cotton_total_price =
      gerber_cotton_price * gerberCotton?.length + gerber_cotton_shippingCost;
  }

  if (organicToddler?.length === 1) {
    organic_toddler_total_price =
      organic_toddler_price + print_provider_initial_ship_price;
  } else if (organicToddler?.length > 1) {
    let organic_toddler_shippingCost =
      print_provider_initial_ship_price +
      print_provider_additional_ship_price * (organicToddler?.length - 1);
    organic_toddler_total_price =
      organic_toddler_price * organicToddler?.length +
      organic_toddler_shippingCost;
  }

  if (organicYouth?.length === 1) {
    organic_youth_total_price =
      organic_youth_price + print_provider_initial_ship_price;
  } else if (organicYouth?.length > 1) {
    let organic_youth_shippingCost =
      print_provider_initial_ship_price +
      print_provider_additional_ship_price * (organicYouth?.length - 1);
    organic_youth_total_price =
      organic_youth_price * organicYouth?.length + organic_youth_shippingCost;
  }

  if (jewelery?.length === 1) {
    jewelery_total_price = jewelery_price + jewelery_initial_ship_price;
  } else if (jewelery?.length > 1) {
    let jewelery_shippingCost =
      jewelery_initial_ship_price +
      jewelery_additional_ship_price * (jewelery?.length - 1);
    jewelery_total_price =
      jewelery_price * jewelery?.length + jewelery_shippingCost;
  }

  if (bracelets?.length === 1) {
    bracelet_total_price = bracelet_price + bracelet_initial_ship_price;
  } else if (bracelets?.length > 1) {
    let bracelet_shippingCost =
      bracelet_initial_ship_price +
      bracelet_additional_ship_price * (bracelets?.length - 1);
    bracelet_total_price =
      bracelet_price * bracelets?.length + bracelet_shippingCost;
  }

  if (keychains?.length === 1) {
    keychain_total_price = keychain_price + keychain_initial_ship_price;
  } else if (keychains?.length > 1) {
    let keychain_shippingCost =
      keychain_initial_ship_price +
      keychain_additional_ship_price * (keychains?.length - 1);
    keychain_total_price =
      keychain_price * keychains?.length + keychain_shippingCost;
  }

  for (let i = 0; i < birthstones?.length; i++) {
    birthstones_total_price =
      birthstones_total_price + birthstones_price + birthstones_ship_price;
  }

  for (let i = 0; i < initials?.length; i++) {
    initials_total_price =
      initials_total_price + initials_price + initials_ship_price;
  }

  for (let i = 0; i < tumbler_lid?.length; i++) {
    tumbler_lid_total_price =
      tumbler_lid_total_price + tumbler_lid_price + tumbler_lid_ship_price;
  }

  for (let i = 0; i < tumbler_straw?.length; i++) {
    tumbler_straw_total_price =
      tumbler_straw_total_price +
      tumbler_straw_price +
      tumbler_straw_ship_price;
  }

  for (let i = 0; i < frosted_cup_lid?.length; i++) {
    frosted_cup_lid_total_price =
      frosted_cup_lid_total_price +
      frosted_cup_lid_price +
      frosted_cup_lid_ship_price;
  }

  for (let i = 0; i < frosted_cup_straw?.length; i++) {
    frosted_cup_straw_total_price =
      frosted_cup_straw_total_price +
      frosted_cup_straw_price +
      frosted_cup_straw_ship_price;
  }

  for (let i = 0; i < frosted_cup_straw_lid_combo?.length; i++) {
    frosted_cup_lid_straw_combo_total_price =
      frosted_cup_lid_straw_combo_total_price +
      frosted_cup_lid_straw_price +
      frosted_cup_lid_straw_ship_price;
  }

  for (let i = 0; i < standard_shipping_tumbler_resend?.length; i++) {
    standard_shipping_tumbler_resend_total_price =
      standard_shipping_tumbler_resend_total_price +
      standard_shipping_ship_price;
  }

  for (let i = 0; i < priority_shipping_tumbler_resend?.length; i++) {
    priority_shipping_tumbler_resend_total_price =
      priority_shipping_tumbler_resend_total_price +
      priority_shipping_ship_price;
  }

  for (let i = 0; i < standard_shipping_jewelry_resend?.length; i++) {
    standard_shipping_jewelry_resend_total_price =
      standard_shipping_jewelry_resend_total_price +
      standard_shipping_ship_price;
  }

  for (let i = 0; i < priority_shipping_jewelry_resend?.length; i++) {
    priority_shipping_jewelry_resend_total_price =
      priority_shipping_jewelry_resend_total_price +
      priority_shipping_ship_price;
  }

  for (let i = 0; i < standard_shipping_ornament_resend?.length; i++) {
    standard_shipping_ornament_resend_total_price =
      standard_shipping_ornament_resend_total_price +
      standard_shipping_ship_price;
  }

  for (let i = 0; i < priority_shipping_ornament_resend?.length; i++) {
    priority_shipping_ornament_resend_total_price =
      priority_shipping_ornament_resend_total_price +
      priority_shipping_ship_price;
  }

  return (
    frosted_cup_total_price +
    tumbler_total_price +
    ornament_total_price +
    jewelery_total_price +
    bracelet_total_price +
    keychain_total_price +
    birthstones_total_price +
    initials_total_price +
    tumbler_lid_total_price +
    tumbler_straw_total_price +
    frosted_cup_lid_total_price +
    frosted_cup_straw_total_price +
    frosted_cup_lid_straw_combo_total_price +
    standard_shipping_tumbler_resend_total_price +
    priority_shipping_tumbler_resend_total_price +
    standard_shipping_jewelry_resend_total_price +
    priority_shipping_jewelry_resend_total_price +
    standard_shipping_ornament_resend_total_price +
    priority_shipping_ornament_resend_total_price +
    gerber_cotton_total_price +
    organic_toddler_total_price +
    organic_youth_total_price
  );
};

function validatePersonalization(object) {
  let validationArray = productAttributes;

  const catalogItem = validationArray.find(
    (item) => item.id === object.catalog.id
  )
    ? validationArray.find((item) => item.id === object.catalog.id)
    : {
        id: object.catalog?.id,
        name: object.catalog?.title,
        needsColor: false,
        needsSize: false,
        no_of_graphics: object?.catalog?.no_of_graphics,
        sizes: null,
      };

  // If catalog item is found
  if (catalogItem) {
    let needsSize = catalogItem.needsSize;
    let needsColor = catalogItem.needsColor;

    // Check personalization_details from object
    let personalizationDetails = object.personalization_details || [];

    // Initialize flags to track if size and color are provided
    let hasSize = false;
    let hasColor = false;

    personalizationDetails.forEach((detail) => {
      if (detail.formatted_name === "Size" && detail.formatted_value) {
        hasSize = true;
      }
      if (detail.formatted_name === "Color" && detail.formatted_value) {
        hasColor = true;
      }
    });

    // Validate size
    if (needsSize && !hasSize) {
      return true;
    }

    // Validate color
    if (needsColor && !hasColor) {
      return true;
    }

    return false;
  } else {
    return true;
  }
}

export const handleOnHoldOrder = (
  order,
  selectedOrder,
  setSelected,
  userType,
  dispatch
) => {
  let containsURL;
  let payload = [];
  let totalAmount = "";
  let totalOrders = 0;
  let addressIncomplete;
  let variationNotUpdated;

  if (Object.keys(order).length === 0 && selectedOrder.length > 0) {
    let finalised_graphics = selectedOrder?.map((order) =>
      order?.orderDetails?.map((item) => getUploadedImage(item))
    );
    let checkIfURL = finalised_graphics[0]?.map((imageURL) =>
      imageURL?.map((url) => checkimageURL(url))
    );

    containsURL = checkIfURL?.some((subArr) => subArr.includes(true));

    let shippingAddress = selectedOrder?.map((order) =>
      order?.orderDetails[0]?.shipping_details
        ? JSON.parse(order?.orderDetails[0]?.shipping_details)
        : {}
    );
    let checkAddresses = shippingAddress?.map((address) =>
      address.billToName || address.shipToName || address.street1 ? false : true
    );

    addressIncomplete = checkAddresses.includes(true);

    let checkIfVariationNotUpdated = selectedOrder?.map((order) =>
      order?.orderDetails?.map((item) => validatePersonalization(item))
    );

    variationNotUpdated = checkIfVariationNotUpdated.some((subArr) =>
      subArr.includes(true)
    );

    totalOrders = selectedOrder.length;

    let allAmounts = selectedOrder?.map((order) => calculateAmount(order));

    totalAmount = allAmounts
      ? allAmounts
          ?.map(Number)
          .reduce((partialSum, a) => partialSum + a, 0)
          .toFixed(2)
      : 0;

    //bulk payload
    if (userType === "Seller") {
      payload = selectedOrder?.map((order) => ({
        order_id: order.id,
        amount: calculateAmount(order),
      }));
    } else if (userType === "PrintProvider") {
      payload = selectedOrder?.map((order) => ({
        order_id: order.id,
        order_item_ids: order?.orderDetails?.map((order) => order.id),
      }));
    } else {
      payload = selectedOrder?.map((order) => ({
        order_id: order.id,
        order_status: "awaiting_shipment",
      }));
    }
  } else {
    let shippingAddress = order?.orderDetails[0]?.shipping_details
      ? JSON.parse(order?.orderDetails[0]?.shipping_details)
      : {};

    totalOrders = 1;
    totalAmount = calculateAmount(order);

    let checkIfVariationNotUpdated = order?.orderDetails?.map((item) =>
      validatePersonalization(item)
    );

    variationNotUpdated = checkIfVariationNotUpdated.some((item) => item);

    let finalised_graphics = order?.orderDetails?.map((item) =>
      getUploadedImage(item)
    );

    let checkIfURL = finalised_graphics[0]?.map((imageURL) =>
      checkimageURL(imageURL)
    );

    containsURL = checkIfURL?.some((item) => item);

    if (
      shippingAddress.billToName ||
      shippingAddress.shipToName ||
      shippingAddress.street1
    ) {
      addressIncomplete = false;
    } else {
      addressIncomplete = true;
    }

    //single payload
    if (userType === "Seller") {
      payload = [
        {
          order_id: order.id,
          amount: totalAmount,
        },
      ];
    } else if (userType === "PrintProvider") {
      payload = [
        {
          order_id: order.id,
          order_item_ids: order?.orderDetails?.map((order) => order.id),
        },
      ];
    } else {
      payload = [
        {
          order_id: order.id,
          order_status: "awaiting_shipment",
        },
      ];
    }
  }

  if (userType === "Seller") {
    if (containsURL) {
      toastify("warning", "Please upload Graphic Images");
    } else if (addressIncomplete) {
      toastify("warning", "Please complete shipping address");
    } else if (variationNotUpdated) {
      toastify("warning", "Please update variations");
    } else {
      dispatch(
        setModalState(
          <ApproveOrder
            amount={totalAmount}
            totalOrders={totalOrders}
            payload={payload}
            setSelected={setSelected}
          />
        )
      );
    }
  } else {
    dispatch(
      setModalState(
        <ApproveOrder
          amount={totalAmount}
          totalOrders={totalOrders}
          payload={payload}
          setSelected={setSelected}
        />
      )
    );
  }
};

export const handleImagesToDownload = async (
  order,
  selectedOrder,
  dispatch
) => {
  var orderIDs;
  dispatch(loadingAction(true));

  if (Object.keys(order).length === 0 && selectedOrder.length > 0) {
    orderIDs = selectedOrder?.map((order) => order.id);
  } else if (Object.keys(order).length) {
    orderIDs = [order.id];
  }

  const payload = {
    order_ids: orderIDs,
  };

  try {
    const res = await request.post(`/orders/bulk/download`, payload);

    if (res) {
      const url = res.data.data[1];
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FileName.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      dispatch(loadingAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
