import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomTable from "../../components/ordersTable/table";
import FilterDropdown from "src/components/filter-dropdown";
import { Box, Chip, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersList } from "./requests/icp";
import { filterOrdersByCatalog } from "./requests/icp/getAdminOrders";

const AdminTable = ({ value, handleChange, ORDERLIST }) => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("All Orders");
  const userType = useSelector((state) => state.user.user.type);
  const orderStats = useSelector((state) => state.orders.orderStats);
  const catalogFilters = useSelector((state) => state.orders.catalogFilters);

  let filterCatalogs = catalogFilters
    ? catalogFilters?.filter((item) => item.no_of_graphics > 0)
    : [];
  let catalogArray = filterCatalogs?.map((item) => item.title || []);
  let filterOptions = ["All orders", ...catalogArray];

  const page =
    sessionStorage.getItem("page") !== null
      ? sessionStorage.getItem("page")
      : 1;

  const rowsPerPage =
    sessionStorage.getItem("rowsPerPage") !== null
      ? sessionStorage.getItem("rowsPerPage")
      : 25;

  const tabValue = sessionStorage.getItem("activeTabIndex")
    ? sessionStorage.getItem("activeTabIndex")
    : "1";
    
  const handleFilterChange = (option) => {
    setSelectedFilter(option);
    let selectedOption = catalogFilters?.filter(
      (item) => item.title === option
    )[0];

    sessionStorage.setItem("selectedCatalogID", selectedOption?.id);
    if (option === "All Orders") {
      getAllOrdersList(dispatch, userType, page, rowsPerPage, tabValue);
    } else {
      filterOrdersByCatalog(
        dispatch,
        userType,
        selectedOption ? [selectedOption?.id] : [],
        page,
        rowsPerPage,
        tabValue
      );
    }
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "white",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            wrapped
            variant="scrollable"
            allowScrollButtonsMobile
          >
            <Tab label={`All Orders (${orderStats?.total || 0})`} value="1" />
            <Tab
              label={`Awaiting Approval (${
                orderStats?.awaiting_approval || 0
              })`}
              value="2"
            />
            <Tab
              label={`Awaiting Shipment (${
                orderStats?.awaiting_shipment || 0
              })`}
              value="3"
            />

            <Tab label={`Shipped (${orderStats?.shipped || 0})`} value="4" />

            <Tab label={`Refunded (${orderStats?.refunded || 0})`} value="5" />
          </TabList>

          <Stack direction={"row"} alignItems={"center"}>
            <FilterDropdown
              options={filterOptions}
              selectedOption={selectedFilter}
              onFilterChange={handleFilterChange}
            />

            <Chip
              label={selectedFilter}
              variant="filled"
              size="small"
              color="primary"
              sx={{ mx: 1 }}
            />
          </Stack>
        </Box>

        <TabPanel sx={{ margin: "0px", padding: "0px" }} value="1">
          <CustomTable ORDERLIST={ORDERLIST} orderStatus="placed" />
        </TabPanel>

        <TabPanel sx={{ margin: "0px", padding: "0px" }} value="2">
          <CustomTable ORDERLIST={ORDERLIST} orderStatus="awaiting approval" />
        </TabPanel>

        <TabPanel sx={{ margin: "0px", padding: "0px" }} value="3">
          <CustomTable ORDERLIST={ORDERLIST} orderStatus="awaiting shipment" />
        </TabPanel>

        <TabPanel sx={{ margin: "0px", padding: "0px" }} value="4">
          <CustomTable ORDERLIST={ORDERLIST} orderStatus="shipped" />
        </TabPanel>

        <TabPanel sx={{ margin: "0px", padding: "0px" }} value="5">
          <CustomTable ORDERLIST={ORDERLIST} orderStatus="refunded" />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default AdminTable;
