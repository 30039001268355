import CanvaLogo from "../../../assets/canva.png";
import KittlLogo from "../../../assets/kittl.jpg";
import AdobeLogo from "../../../assets/adobe.jpeg";

export const getLink = (link) => {
  let url = Array.isArray(link)
    ? link[0]?.url
    : typeof link == "string"
    ? link
    : link?.url;

  let canvaUrl = url.search("canva");
  let kittleURL = url.search("kittl");
  let adobeURL = url.search("adobe");

  if (canvaUrl > 0 || kittleURL > 0 || adobeURL > 0) {
    return url;
  }
};

export const getUploadedImage = (item, type) => {
  let graphic_image = item ? item.graphic_image : "";
  let notecard_image = item ? item.notecard_image : "";
  let grouped_images = [graphic_image, notecard_image];

  let newArray = grouped_images?.map((link) => {
    let imageLink = link
      ? Array.isArray(link)
        ? link[1]?.url
        : link?.url
      : "";
    let url = Array.isArray(link)
      ? link[0]?.url
      : typeof link == "string"
      ? link
      : link?.url;

    let image = imageLink?.search("image");
    let canvaUrl = url?.search("canva");
    let kittleURL = url?.search("kittl");
    let adobeURL = url?.search("adobe");

    if (image > 0) {
      return imageLink;
    } else {
      if (canvaUrl > 0) {
        return CanvaLogo;
      }
      if (kittleURL > 0) {
        return KittlLogo;
      }
      if (adobeURL > 0) {
        return AdobeLogo;
      }
    }
  });

  let array = newArray.filter((item) => item !== undefined);
  if (type === "graphic") {
    return array[0];
  } else if (type === "notecard") {
    return array[1];
  } else {
    return newArray.filter((item) => item !== undefined);
  }
};

export const checkURL = (link) => {
  let url = Array.isArray(link)
    ? link[0]?.url
    : typeof link == "string"
    ? link
    : link?.url;

  const canvaUrl = url?.search("canva");
  const kittleURL = url?.search("kittl");
  const adobeURL = url?.search("adobe");

  if (canvaUrl > 0 || kittleURL > 0 || adobeURL > 0) {
    return "url";
  } else {
    return "image";
  }
};

export const saveImage = (imageBase64) => {
  const link = document.createElement("a");
  link.href = imageBase64;
  link.download = "mockup-image.png";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function base64ToFile(base64String, filename) {
  const base64Data = base64String.split(",")[1];
  const binaryData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });

  const file = new File([blob], filename, {
    type: "application/octet-stream",
  });

  return file;
}
