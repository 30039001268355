import React, { useMemo } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";

import {
  Stack,
  TextField,
  Alert,
  CircularProgress,
  Typography,
  FormControl,
  Button,
  Divider,
  Box,
} from "@mui/material";
import request from "src/utils/request";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { toastify } from "src/utils/toast";
import { CartContainer } from "../Cart.styles";
import {
  loadingBtn2Action,
  loadingBtnAction,
  setActiveStep,
} from "src/redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { updateShippingAddress } from "src/pages/orders/requests/icp/updateShippingAddress";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(3, "First Name must be at least 3 characters")
    .max(32, "First Name must be at most 32 characters")
    .typeError("First Name must be a valid string")
    .required("First Name is required"),
  lastName: yup
    .string()
    .min(3, "Last Name must be at least 3 characters")
    .max(32, "Last Name must be at most 32 characters")
    .required("Last Name is required"),
  company: yup.string(),
  phone: yup.string(),
  state: yup
    .string()
    .min(1, "State is required")
    .max(32, "State must be at most 32 characters")
    .required("State is required"),
  street1: yup
    .string()
    .min(3, "Street Address 1 must be at least 3 characters")
    .max(32, "Street Address 1 must be at most 32 characters")
    .required("Street Address 1 is required"),
  street2: yup.string().typeError("Street Address 2 must be a valid string"),
  city: yup
    .string()
    .min(3, "City must be at least 3 characters")
    .max(32, "City must be at most 32 characters")
    .required("City is required"),
  postal_code: yup.string().required("Postal Code is required"),
});

const ShippingForm = ({ shippingAddress, orderID }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addressFetched, setAddressfetched] = useState(false);
  const [fullAddress, setFullAddress] = useState("");

  const userType = useSelector((state) => state.user.user.type);
  const loadingBtn = useSelector((state) => state.user.loadingButton);
  const loadingBtn2 = useSelector((state) => state.user.loadingButton2);

  const options = useMemo(() => countryList().getData(), []);
  const [country, setCountry] = useState({
    value: shippingAddress ? shippingAddress?.country : "US",
    label: shippingAddress
      ? options?.filter((item) => item.value === shippingAddress.country)[0]
          .label
      : "United States",
  });
  console.log(country, "country");

  const nameArray = shippingAddress?.billToName
    ? shippingAddress?.billToName.split(" ")
    : [];
  const firstName = nameArray?.length ? nameArray[0] : "";
  const lastName = nameArray?.length ? nameArray?.slice(1).join(" ") : "";

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: shippingAddress?.billToName ? firstName : "",
      lastName: shippingAddress?.state ? lastName : "",
      company: shippingAddress?.company ?? "",
      phone: shippingAddress?.phone ?? "",

      state: shippingAddress?.state ?? "",
      street1: shippingAddress?.street1 ?? "",
      street2: shippingAddress?.street2 ?? "",
      city: shippingAddress?.city ?? "",
      postal_code: shippingAddress?.postal_code ?? "",
    },
  });

  const onSubmitHandler = (data) => {
    dispatch(loadingBtnAction(true));
    reset();

    const placeOrderPayload = {
      billToName: data.firstName + " " + data.lastName,
      shipToName: data.firstName + " " + data.lastName,
      company: data.company,
      phone: data.phone,
      country: country.value,
      state: data.state,
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      postal_code: data.postal_code,
    };
    const updateAddressPayload = {
      order_id: orderID,
      shipping_info: placeOrderPayload,
    };

    if (shippingAddress) {
      updateShippingAddress(dispatch, updateAddressPayload, userType);
    } else {
      placeManualOrder(placeOrderPayload);
    }
  };

  const placeManualOrder = async (newOrder) => {
    dispatch(loadingBtnAction(true));
    try {
      const res = await request.post(`/orders`, newOrder);

      if (res) {
        toastify("success", res.data.message);
        navigate("/dashboard/orders");
        dispatch(loadingBtnAction(false));
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      dispatch(loadingBtnAction(false));
    }
  };

  const handleInputChange = (event) => {
    setFullAddress(event.target.value);
  };

  const fillFormFields = async () => {
    dispatch(loadingBtn2Action(true));
    try {
      const res = await request.post(`/orders/address/split`, {
        address: fullAddress,
      });

      if (res) {
        reset(res.data.data);
        setCountry(res.data.data.country);
        setAddressfetched(true);
        dispatch(loadingBtn2Action(false));
      }
    } catch (e) {
      toastify("error", e.response.data.message);
      dispatch(loadingBtn2Action(false));
    }
  };

  return (
    <CartContainer>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Typography variant="h4" gutterBottom>
          Recipient
        </Typography>
        <Stack spacing={3}>
          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("firstName")}
              type="text"
              label="First Name"
              error={Boolean(errors.firstName?.message)}
              helperText={errors.firstName?.message || ""}
              required
            />

            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("lastName")}
              type="text"
              label="Last Name"
              error={Boolean(errors.lastName?.message)}
              helperText={errors.lastName?.message || ""}
              required
            />
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("company")}
              type="text"
              label="Company"
            />

            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("phone")}
              type="number"
              label="Phone"
            />
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }}></Stack>
        <Typography variant="h4" gutterBottom>
          Shipping address
        </Typography>

        {orderID ? (
          <></>
        ) : (
          <>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              spacing={4}
              alignItems={"center"}
            >
              <TextField
                name="fullAddress"
                placeholder="Enter full address..."
                type="text"
                value={fullAddress}
                onChange={handleInputChange}
                fullWidth
              />
              <Box>
                <LoadingButton
                  size="small"
                  loading={loadingBtn2}
                  type="button"
                  loadingIndicator={
                    <CircularProgress color="inherit" size={12} />
                  }
                  onClick={fillFormFields}
                  variant="contained"
                >
                  Fill
                </LoadingButton>
              </Box>
            </Stack>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                OR
              </Typography>
            </Divider>

            {addressFetched && (
              <Alert severity="info">
                Please check address before placing order.
              </Alert>
            )}
          </>
        )}

        <Stack spacing={3} mt={3}>
          <Stack direction={"row"} justifyContent="space-between">
            <FormControl fullWidth>
              <Select
                options={options}
                defaultValue={country}
                onChange={(e) => setCountry(e)}
                placeholder="Select a country..."
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "#212B36"
                      : "rgba(145, 158, 171, 0.32)",
                    height: "55px",
                    borderRadius: "6px",
                    zIndex: 999,
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    maxHeight: "249px",
                  }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </FormControl>
            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("state")}
              type="text"
              placeholder="State"
              error={Boolean(errors.state?.message)}
              helperText={errors.state?.message || ""}
              required
            />
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("street1")}
              type="text"
              placeholder="Street 1"
              error={Boolean(errors.street1?.message)}
              helperText={errors.street1?.message || ""}
              required
            />

            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("street2")}
              type="text"
              placeholder="Street 2"
            />
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <TextField
              fullWidth
              {...register("city")}
              type="text"
              placeholder="City"
              error={Boolean(errors.city?.message)}
              helperText={errors.city?.message || ""}
              required
            />

            <Stack direction="column" sx={{ mx: 2 }}></Stack>
            <TextField
              fullWidth
              {...register("postal_code")}
              type="text"
              placeholder="Postal Code"
              error={Boolean(errors.postal_code?.message)}
              helperText={errors.postal_code?.message || ""}
              required
            />
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ my: 2 }}></Stack>

        <Stack direction="row" justifyContent={"end"}>
          {orderID ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => dispatch(setActiveStep(0))}
              >
                Back
              </Button>
              <Stack direction="column" sx={{ mx: 1 }}></Stack>
            </>
          )}

          <LoadingButton
            type="submit"
            loading={loadingBtn}
            loadingIndicator={<CircularProgress color="inherit" size={12} />}
            variant="contained"
          >
            {orderID ? "Update" : "Place Order"}
          </LoadingButton>
        </Stack>
      </form>
    </CartContainer>
  );
};

export default ShippingForm;
