import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { Box, List, ListItemText, Stack } from "@mui/material";

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data?.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        borderRadius: "40px",
        "&.active": {
          color: "#02B2FE",
          bgcolor: "#D1E9FC",
          fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <Stack direction={"row"} alignItems={"center"} spacing={3}>
        <ListItemText disableTypography primary={title} />
      </Stack>

      {info && info}
    </StyledNavItem>
  );
}
