import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Typography,
  Stack,
  Box,
  Paper,
  Avatar,
  useTheme,
  Grid,
  IconButton,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { getContent } from "./request";
import AddContent from "src/sections/modals/education/add-content/AddContent";
import { isEmpty } from "lodash";
import EducationUrls from "./EducationUrls";
import EducationIcon from "../../assets/icons/navbar/education_1.svg";
import SvgColor from "src/components/svg-color/SvgColor";
import { categorizeMediaItems } from "./formatData";
import EducationVideo from "./EducationVideo";
import EducationImages from "./EducationImages";
import EducationDocs from "./EducationDocs";
import UpdateEducationSection from "src/sections/modals/education/UpdateEducationSection";
import DeleteEducationSection from "src/sections/modals/education/DeleteEducationSection";

const Education = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const isLoading = useSelector((state) => state.user.isLoading);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const content = useSelector((state) => state.education.educationContent);

  useEffect(() => {
    getContent(dispatch);
    dispatch(setModalState(undefined));
  }, []);

  useEffect(() => {
    if (content) {
      const firstKey = Object.keys(content)[0];
      const firstValue = content[firstKey]?.contents;
      const formattedContent = firstValue
        ? categorizeMediaItems(firstValue)
        : [];
      setSelectedTitle(firstKey);
      setSelectedSection(formattedContent);
    }
  }, [content]);

  const Section = ({ item, title }) => {
    const sectionID = item[0]?.educationalContentSection?.id;

    return (
      <Paper
        sx={{
          position: "relative",
          width: "440px",
          marginY: 3,
          padding: "20px",
          cursor: "pointer",
          bgcolor: selectedTitle === title ? theme.palette.primary.main : "",
        }}
        elevation={3}
        onClick={() => {
          const formattedContent = item ? categorizeMediaItems(item) : [];
          setSelectedSection(formattedContent);
          setSelectedTitle(title);
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Avatar
              sx={{
                bgcolor:
                  selectedTitle === title
                    ? theme.palette.white.main
                    : theme.palette.primary.main,
              }}
            >
              <SvgColor
                src={EducationIcon}
                sx={{
                  width: 1,
                  height: 1,
                  color:
                    selectedTitle === title
                      ? theme.palette.primary.main
                      : theme.palette.white.main,
                }}
              />
            </Avatar>
            <Typography
              sx={{
                color: selectedTitle === title ? theme.palette.white.main : "",
              }}
              variant="h6"
            >
              {title}
            </Typography>
          </Stack>
          {userType === "Super Admin" && (
            <Box>
              <IconButton
                onClick={() => {
                  dispatch(
                    setModalState(
                      <UpdateEducationSection id={sectionID} title={title} />
                    )
                  );
                }}
              >
                <Iconify
                  icon="eva:edit-fill"
                  color={
                    selectedTitle === title
                      ? theme.palette.white.main
                      : theme.palette.primary.main
                  }
                />
              </IconButton>
              <IconButton
                onClick={() =>
                  dispatch(
                    setModalState(<DeleteEducationSection id={sectionID} />)
                  )
                }
              >
                <Iconify
                  icon="eva:trash-2-outline"
                  color={
                    selectedTitle === title
                      ? theme.palette.white.main
                      : theme.palette.primary.main
                  }
                />
              </IconButton>
            </Box>
          )}
        </Stack>
      </Paper>
    );
  };

  return (
    <Container maxWidth="2xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={0}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          Growth Materials
        </Typography>
        {userType === "Super Admin" && (
          <Button
            variant={"contained"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => dispatch(setModalState(<AddContent add />, true))}
          >
            Add Content
          </Button>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"} spacing={4}>
        {!isEmpty(content) ? (
          <>
            {" "}
            <Box>
              {Object.entries(content)?.map(([key, value]) => (
                <Section item={value.contents} title={key} />
              ))}
            </Box>
            <Paper sx={{ width: "100%", padding: "20px" }} elevation={3}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {selectedTitle ? selectedTitle : ""}
              </Typography>

              <Grid container spacing={2}>
                {selectedSection?.urls?.map((item, index) => (
                  <EducationUrls key={index} item={item} />
                ))}
              </Grid>

              {selectedSection?.docs?.map((item, index) => (
                <EducationDocs key={index} index={index} item={item} />
              ))}

              {selectedSection?.mp4?.map((item, index) => (
                <EducationVideo key={index} item={item} />
              ))}
              <Grid container spacing={2}>
                {selectedSection?.images?.map((item, index) => (
                  <EducationImages key={index} item={item} />
                ))}
              </Grid>
            </Paper>
          </>
        ) : !isLoading ? (
          <Paper elevation={3} sx={{ width: "100%" }}>
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ mb: 1 }}>
                There is no content
              </Typography>
            </Box>
          </Paper>
        ) : (
          ""
        )}
      </Stack>
    </Container>
  );
};

export default Education;
